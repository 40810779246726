h1,
h2,
h3,
h4 {
  font-weight: 500;
  line-height: 130%;
}

h1 {
  font-size: ac(70px, 37px);
  line-height: normal;

  @mixin max-sm {
    line-height: 129%;
  }
}

h2 {
  font-size: ac(50px, 27px);
  line-height: normal;

  @mixin max-sm {
    line-height: 140%;
  }

  &.subtitle {
    font-size: ac(35px, 22px);
    line-height: 140%;
  }
}

h3 {
  font-size: ac(25px, 22px);
  line-height: normal;
  font-weight: 600;
}

h4 {
  font-size: ac(20px, 18px);
  line-height: 140%;
  font-weight: 400;

  @mixin max-sm {
    line-height: 155%;
  }
}

h5 {
  font-size: ac(28px, 20px);
  font-weight: 500;
  line-height: 1.5;
}

h6 {
  font-size: ac(24px, 18px);
  font-weight: 500;

  line-height: 2;
}

p {
  font-size: ac(18px, 16px);
  font-weight: 400;
  color: var(--cl-medium-grey);

  line-height: 144%;

  @mixin max-sm {
    line-height: 162%;
  }
}

li {
  font-size: ac(18px, 16px);
  font-weight: 400;
  color: var(--cl-medium-grey);

  line-height: 144%;

  @mixin max-sm {
    line-height: 162%;
  }
}

a {
  font-size: ac(20px, 16px);
  font-weight: 500;
  display: inline-block;
  line-height: 100%;
}

.content-element {
  h1,
  h2,
  h3,
  h4,
  h5,
  h6 {
  }

  h4 {
    color: var(--cl-medium-grey);
    font-weight: 500;

    &:not(:last-child) {
      margin-bottom: ac(20px, 16px);
    }
  }

  ol,
  ul {
    li {
      list-style-position: inside;
    }
  }

  ol {
    list-style: decimal;
  }

  ul {
    list-style: disc;
  }

  li,
  p {
    /*Not Design*/
    &:not(:last-child) {
      margin-bottom: ac(20px, 16px);
    }

    /*a {
      font: inherit;
      color: var(--cl-orange);
      transition: color .3s ease;
      text-decoration: underline;

      &:hover {
        color: var(--cl-slate);
      }
    }*/
  }

  &.big-size {
    h2 {
      font-family: var(--font-main);
      font-weight: 700;
      font-size: ac(60px, 35px);
      line-height: normal;

      &:not(:last-child) {
        margin-bottom: ac(32px, 20px);
      }
    }

    p, li {
      font-family: var(--font-second);
      font-weight: 400;
      font-size: ac(26px, 18px);
      line-height: normal;

      &:not(:last-child) {
        margin-bottom: ac(24px, 16px);
      }
    }

    &.first-big-paragraph {
      p {
        &:first-of-type {
          font-family: var(--font-main);
          font-weight: 700;
          font-size: ac(40px, 28px);
          line-height: normal;
        }

      }
    }
  }
}

.dark-bg {
  h1,
  h2,
  h3,
  h4,
  h5,
  h6,
  p,
  li {
    color: var(--cl-white);
  }
}
