.fp-watermark {
  display: none;
}

html {
  scrollbar-width: thin;
  scrollbar-color: var(--cl-orange) transparent;
}

body {
  position: relative;
  min-width: 320px;
  margin: auto;
  background: var(--cl-white);

  color: var(--cl-slate);
  font-family: var(--font-main);

  overflow-x: hidden;

  min-height: calc(100 * var(--vh));
  display: flex;

  &::-webkit-scrollbar {
    width: 6px;
  }

  &::-webkit-scrollbar-track {
    background: var(--cl-grey);
    border-radius: 0;
  }

  &::-webkit-scrollbar-thumb {
    background: var(--cl-orange);
    border-radius: 0;
  }
}

.swiper-wrapper {
  left: 0 !important;
}

#site {
  position: relative;
  flex-grow: 1;
  display: flex;
  flex-direction: column;
}

.main {
  flex-grow: 1;
}

.cont {
  margin: 0 auto;
  max-width: 1276px;
  width: perc(1276);

  @mixin max-lg {
    width: 89.33%;
  }
}

section {
  position: relative;
}

.logo {
  transition: 0.3s ease;

  img {
    width: 100%;
    height: 100%;
    object-fit: contain;
  }

  &:hover {
    transform: scale(1.06);
  }
}

#bold-credits {
  width: 148px;
  transition: ease-in-out 0.25s;

  &::before,
  &::after {
    display: none;
  }

  &:hover {
    transform: scale(1.1);
  }

  @mixin max-sm {
    width: 159px;
  }
}

#site.cookie-terms-page {
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  justify-content: stretch;
  align-items: stretch;

  .main {
    flex-grow: 1;
    padding: 0;
  }
}

.cookie-terms-section {
  padding: ac(250px, 140px) 0 ac(150px, 50px);
  p {
    padding: 0;
    margin-bottom: 10px;
  }
  ul,
  ol {
    padding-left: 20px;
    li {
      p {
        margin-bottom: 0;
      }
    }
  }

  ul {
    list-style: circle;
  }

  ol {
    list-style: decimal;
  }

  a {
    display: inline;
    text-decoration: underline;
    transition: 0.2s;

    &:hover {
      opacity: 0.7;
    }
  }
}

.single-page-404 {
  min-height: 100vh;
  padding: 20px;

  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;

  h1,
  p {
    margin-bottom: 15px;
    padding: 0;
  }

  a {
    display: inline-block;
    margin-top: 20px;
  }
}

.social-link {
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 20px;
  border-radius: 6px;
  border: 2px solid var(--cl-slate);
  overflow: hidden;
  background: var(--cl-slate);
  color: var(--cl-orange);
  transition: color 0.3s ease;
  width: 32px;
  height: 32px;
  position: relative;

  i {
    position: relative;
    z-index: 5;
  }

  &:before {
    position: absolute;
    inset: 0;
    background: var(--cl-orange);
    content: '';
    transform: translateY(100%);
    transition: transform 0.3s ease;
  }

  /*i.icon-youtube {
    margin-bottom: -6px;
  }*/

  &:hover {
    color: var(--cl-grey);

    &:before {
      transform: translateY(0);
    }
  }
}

.socials {
  display: flex;
  align-items: center;
  justify-content: flex-start;
  gap: 10px;
}

.holding {
  overflow: hidden;
  position: relative;
  z-index: 5;

  &__bg {
    @mixin aspect-ratio 2288, 1152;
    width: 150%;
    position: absolute;
    top: 0;
    left: 50%;
    transform: translateY(-60%)translateX(-12%);
    max-width: 2300px;

    @mixin max-sm {
      display: none;
    }
  }

  &__bg-container {
    @mixin aspect-ratio-block 799, 498;
    width: 62.62%;
    position: absolute;
    top: 50%;
    right: 0;
    transform: translateY(-50%) translateX(30%) !important;
    overflow: visible !important;
    z-index: -1;
    min-width: 420px;

    @mixin max-sm {
      opacity: .8;
    }
  }

  &__bg-circle {
    display: flex;
    justify-content: center;
    align-items: center;
    position: absolute;
    top: 0;
    left: 0;
    width: 62.33%;

    .circle {
      @mixin aspect-ratio-block 1, 1;
      width: 100%;
      background: var(--cl-light-grey);
      border-radius: 50%;
      display: block;
    }

    &:nth-child(1) {
      z-index: 2;
    }

    &:nth-child(2) {
      left: auto;
      right: 0;

      .circle {
        background-color: var(--cl-orange);

        @mixin max-sm {
          opacity: .5;
        }
      }
    }
  }

  &__wrap {
    padding-top: ac(140px, 64px);
    padding-bottom: ac(140px, 64px);
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: center;
    position: relative;
    z-index: 10;
    min-height: 100vh;
  }

  &__logo {
    @mixin aspect-ratio 291, 84;
    width: 100%;
    max-width: ac(350px, 300px);
    display: flex;
    justify-content: center;
    align-items: center;
    margin-top: ac(-40px, 0px);

    &:not(:last-child) {
      margin-bottom: ac(50px, 30px);
    }
  }

  &__text {
    width: 100%;
    max-width: ac(820px, 680px);

    &:not(:last-child) {
      margin-bottom: ac(40px, 24px);
    }

    .underline {
      text-underline-offset: ac(10px, 8px);
    }
  }

  &__socials {

    &:not(:last-child) {
      margin-bottom: ac(40px, 24px);
    }
  }

  &__links {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: flex-start;
  }

  &__link {
    display: inline-flex;
    font-weight: 600;
    font-size: ac(26px, 18px);
    line-height: normal;
    /*display: flex;*/
    justify-content: center;
    align-items: center;
    text-align: center;
    position: relative;
    z-index: 5;
    transition: color .31s ease;
    color: var(--cl-orange);

    &:before {
      bottom: 0;
      left: 0;
      right: 0;
      content: '';
      height: 2px;
      background: var(--cl-orange);
      transition: background-color 0.4s ease, height 0.2s ease;
      position: absolute;
      z-index: -1;
    }

    &:hover {
      color: var(--cl-slate);
      &:before {
        transition: background-color 0.3s ease, height 0.3s ease;
        height: 50%;
        background-color: var(--cl-orange);
      }
    }

  }
}
