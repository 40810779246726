@import url('https://fonts.googleapis.com/css2?family=Plus+Jakarta+Sans:ital,opsz,wght@0,9..40,300;0,9..40,400;0,9..40,500;0,9..40,600;0,9..40,700;1,9..40,300;1,9..40,400;1,9..40,500;1,9..40,600;1,9..40,700&family=Inter:wght@200;300;400;500;600;700;800&display=swap');

:root {
  --cl-black: #000000;
  --cl-black-rgb: 0 0 0;

  --cl-primary: #ec612a;
  --cl-primary-rgb: 236 97 42;

  --cl-white: #ffffff;
  --cl-white-rgb: 255 255 255;

  --cl-orange: #EC731F;

  --cl-grey: #D2D2D2;

  --cl-light-grey: #F6F6F6;

  --cl-slate: #20232E;

  --font-main: 'Plus Jakarta Sans', sans-serif;
  --font-second: 'Inter', sans-serif;

  /* --mobile-menu-start-point and @custom-media --mobile-menu-start-point must be the same */
  --mobile-menu-start-point: 1200;

  --vh: calc(100vh / 100);
}

/* --mobile-menu-start-point and @custom-media --mobile-menu-start-point must be the same */
/* @custom-media --mobile-menu-start-point (max-width: 1200px); */

/* --mobile-menu-end-point must be greater than 1 of the  --mobile-menu-start-point and @custom-media --mobile-menu-start-point */
/* @custom-media --mobile-menu-end-point (min-width: 1201px); */
